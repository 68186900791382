import { template as template_d62d24f8f1594b3791f30b164000fdb7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d62d24f8f1594b3791f30b164000fdb7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
