import { template as template_a34b9701c24d40f2a06d37458bf63b08 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a34b9701c24d40f2a06d37458bf63b08(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
